@import '../partials';

.alert-modal {
  display: none;

  width: 100%;
  background-color: $gunmetal;
  z-index: 200;

  .header--mobile-menu & {
    height: 0;
  }

  &__boundary {
    padding: rem(16) 5%;
  }

  &__dismiss {
    background-image: url('../DocumentDefault/Assets/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: 0;
    width: rem(22);
    height: rem(22);
    position: absolute;
    right: 5%;
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: rem(40);
    padding-left: rem(40);
  }

  &__text {
    color: $white;
    font-size: rem(18);
    font-weight: $font-weight-book;
    text-align: center;
  }

  &__link {
    margin-left: rem(12);
    text-transform: uppercase;
    color: $white;
    font-size: rem(14);
    border-bottom: 1px solid $white;
    font-weight: $font-weight-heavy;
  }
}